import React from 'react'
import PropTypes from "prop-types"
import withLocation from "~/hoc/withLocation"
const windowGlobal = typeof window !== 'undefined' && window

const CustomQueryStringComponent = ({ location }) => {
  if(location)
  {
    const path = location.pathname
    const affId = path.replace('/with/', '')

    windowGlobal && localStorage.setItem('affiliateCode', affId)
    return(
      <>
      Welcome {affId}
      </>
    )
  }
  return(
    <>
      Welcome!
    </>
  )
}

CustomQueryStringComponent.propTypes = {
  location: PropTypes.object,
}

export default withLocation(CustomQueryStringComponent)